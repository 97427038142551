
        export default [{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/appoint/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            return props.record;
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/appoint/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/appoint/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/appoint',
    auth: false,
    layout: false,
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/appointment/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/appointment/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/appointment',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/area/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看村居';

            return props.isEdit ? '编辑村居' : '创建村居';
        },
        width: '20%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/area/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/area/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/area',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftFourPage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftFourPage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/leftFoure',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftOnePage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftOnePage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/leftOner',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftThreePage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftThreePage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/leftThreer',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftTwoPage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/components/LeftTwoPage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lefTwoe',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/dashboard/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/dashboard',
    title: '首页',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/LeftFourPage/index.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/LeftOnePage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/LeftOnePage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/leftone',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/LeftTwoPage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/LeftTwoPage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lefttwo',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/MiddleMapPage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/MiddleMapPage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/middlemap',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/RightPage/index.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/components/TopPage/index.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/davpage/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/davpage',
    title: '大数据',
    auth: false,
    layout: false,
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/department/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看';

            return props.isEdit ? '编辑' : '创建';
        },
        width: '20%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/department/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/department/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/department',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/departmetday/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看';

            return props.isEdit ? '编辑' : '创建';
        },
        width: '20%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/departmetday/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/departmetday/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/departmentday',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_discover/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看事件';

            return props.isEdit ? '编辑事件' : '创建事件';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_discover/LineModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title:"历史记录",
        width: '40%',
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_discover/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_discover/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/event_discover',
    title: '事件发现',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_level/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看';

            return props.isEdit ? '编辑' : '创建';
        },
        width: '20%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_level/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_level/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/event_level',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_regist/AllocatModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: '选择指派人',
        width: '30%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_regist/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看事件';

            return props.isEdit ? '编辑事件' : '创建事件';
        },
        width: '50%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_regist/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_regist/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/event_regist',
    title: '事件登记',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_type/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看';

            return props.isEdit ? '编辑' : '创建';
        },
        width: '20%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_type/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/event_type/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/event_type',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/home/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/home/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/',
    title: '首页',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/law/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/law/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lawpage',
    auth: false,
    layout: false,
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawconte/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看';

            return props.isEdit ? '编辑' : '创建';
        },
        width: '20%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawconte/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawconte/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lawconte',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawlist/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        width: '90%',
        height:'90%',
        top: 10,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawlist/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawlist/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lawlist',
    auth: false,
    layout: false,
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawlist/video.js',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawupload/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/lawupload/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lawupload',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/list/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/list/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/lists',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/login/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/login/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/login',
    auth: false,
    layout: false,
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/logs/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/logs/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/systemlogs',
    title: '系统日志',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/menus/ActionEdit.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/menus/MenuEdit.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/menus/MenuTableSelect.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/menus/SystemSelect.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/menus/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/menus/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/menus',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/online/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/online/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/onlines',
    title: '用户在线时长',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/page-configs.js',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/role/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => (props.isEdit ? '编辑角色' : '创建角色'),
        width: '70%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/role/RoleSelectTable.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/role/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/role/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/roles',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/setting/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/setting/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/setting',
    title: '系统设置',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/user/EditModal.jsx',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '查看用户';

            return props.isEdit ? '编辑用户' : '创建用户';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/user/index.jsx',
            component: ()=> import('/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/user/index.jsx'),
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        
    path: '/users',
},{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/utils/full.js',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        },{
            filePath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages/utils/index.js',
            
            pagesPath: '/Users/zhangdongsheng/Documents/yiken/mtproject/mtadmin/src/pages',
        }]
    